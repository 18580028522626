<div class="fennechLoaderGeneralContainer">
  <img src="/assets/Images/Logos/logoFennechAnimado.gif" alt="" />
  <div class="loader">
    <div>
      <p>Cargando</p>
      <div class="dots-container">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
  </div>
</div>
