import { Component, Injector, OnInit } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { RegisterApiCallsHandlerClass } from './class/register-api-calls-handler-class';
import { RegisterVariableHandlerClass } from './class/register-variable-handler-class';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  constructor(
    protected DDISE: DefaultDependenciesInjectorService,
    public apiCallsHandlerClass: RegisterApiCallsHandlerClass,
    public variableCallsHandlerClass: RegisterVariableHandlerClass,
  ) {
    apiCallsHandlerClass = new RegisterApiCallsHandlerClass(
      variableCallsHandlerClass,
    );
  }

  ngOnInit(): void {}
}
