import { Component, Injector, OnInit } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { SendRecoveryEmailApiCallsHandlerClass } from './class/send-recovery-email-api-calls-handler-class';
import { SendRecoveryEmailVariableHandlerClass } from './class/send-recovery-email-variable-handler-class';

@Component({
  selector: 'app-send-recovery-email',
  templateUrl: './send-recovery-email.component.html',
  styleUrls: ['./send-recovery-email.component.scss'],
})
export class SendRecoveryEmailComponent implements OnInit {
  constructor(
    protected DDISE: DefaultDependenciesInjectorService,
    public apiCallsHandlerClass: SendRecoveryEmailApiCallsHandlerClass,
    public variableCallsHandlerClass: SendRecoveryEmailVariableHandlerClass,
  ) {
    apiCallsHandlerClass = new SendRecoveryEmailApiCallsHandlerClass(
      variableCallsHandlerClass,
      DDISE,
    );
  }

  ngOnInit(): void {}

  dispatchSendRecoveryEmailAction() {
    this.apiCallsHandlerClass.sendRecoveryEmail();
  }
}
