<div class="sendRecoveryEmailGeneralContainer">
  <div class="left">
    <form
      (submit)="
        variableCallsHandlerClass.isDataCompleted &&
        variableCallsHandlerClass.isValidFormat
          ? dispatchSendRecoveryEmailAction()
          : null
      "
    >
      <div class="logo">
        <img src="/assets/Images/Logos/Fenech_V2-20.png" />
      </div>
      <div class="welcomeMessageContainer">
        <p class="title">Recuperación de contraseña</p>
        <p class="subtitle">Introduce el email para recuperar tu cuenta</p>
      </div>
      <div class="formElementsContainer">
        <customInput
          [options]="variableCallsHandlerClass.emailOptions"
          [(ngModel)]="variableCallsHandlerClass.recoveryData.email"
          name="username"
          (input)="variableCallsHandlerClass.checkIfCompletedAndValidFormat()"
          (change)="variableCallsHandlerClass.checkIfIsValidEmailFormat()"
        >
        </customInput>
        <btn
          class="action"
          [ngClass]="{
            disabled:
              !variableCallsHandlerClass.isDataCompleted ||
              !variableCallsHandlerClass.isValidFormat,
          }"
        >
          <ng-container>
            <span>Iniciar sesión</span>
          </ng-container>
        </btn>
        <a class="recoveryPassword" routerLink="/login" routerAnimationTop
          >Ya he recordado mi contraseña</a
        >
      </div>
    </form>
    <accessManagementFormFooter />
  </div>
  <div class="right"></div>
</div>
