import { Injectable } from '@angular/core';
import { SendRecoveryEmailVariableHandlerClass } from './send-recovery-email-variable-handler-class';
import {
  StaticUtilitiesService,
  iResultHttp,
  iStaticUtilities,
  slideNavigationTypes,
} from '@quasar-dynamics/ngx-qd-design-system';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { filter, takeUntil } from 'rxjs';
import { RecoveryService } from 'src/app/Services/Api/recovery.service';

@Injectable({
  providedIn: 'root',
})
export class SendRecoveryEmailApiCallsHandlerClass {
  constructor(
    public variableCallsHandlerClass: SendRecoveryEmailVariableHandlerClass,
    public DDISE: DefaultDependenciesInjectorService,
    private recoverySE?: RecoveryService,
  ) {}

  /**
   * API RESPONSES
   */
  successSendRecoveryEmailResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.DDISE.generalLoader.removeFromLoaderAmount();
    localStorage.setItem(
      iStaticUtilities.simpleCrypt('email'),
      this.variableCallsHandlerClass.recoveryData.email,
    );
    this.DDISE.staticUtilities.goTo(
      '/notificacion-recuperacion-contraseña',
      slideNavigationTypes.slideToTop,
    );
  }

  /**
   * CALLS TO API
   */
  sendRecoveryEmail() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.DDISE.generalLoader.addToLoaderAmount();
    this.recoverySE?.sendRecoveryEmail(
      behaviorSubject,
      this.variableCallsHandlerClass.recoveryData,
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res),
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successSendRecoveryEmailResponseHandler(res),
            error: false,
          },
          {
            method: () => this.DDISE.generalLoader.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
