import { Injectable } from '@angular/core';
import {
  iNavigationMenuOptions,
  iStaticUtilities,
} from '@quasar-dynamics/ngx-qd-design-system';

@Injectable({
  providedIn: 'root',
})
export class StaticDataHandlerService {
  private sidebarNavigationMenuOptions: iNavigationMenuOptions = {
    type: 'sidebar',
    links: [
      {
        label: 'Dashboard',
        icon: '/assets/Icons/Sidebar/dashboard.svg',
        url: '/dashboard',
        activeUrl: '/dashboard',
        animation: 'top',
        canBeOpened: false,
        disabled: true,
      },
      {
        label: 'Lineas de negocio',
        icon: '/assets/Icons/Sidebar/bussiness-line.svg',
        url: '/lineas-negocio/listado',
        activeUrl: '/lineas-negocio',
        animation: 'top',
        canBeOpened: false,
        disabled: true,
      },
      {
        label: 'Portfolio',
        icon: '/assets/Icons/Sidebar/portfolio.svg',
        url: '/portfolio',
        activeUrl: '/portfolio',
        animation: 'top',
        canBeOpened: true,
        disabled: true,
        nestedLinks: [
          {
            label: 'Cotizador',
            url: '/portfolio/cotizador/nuevo-presupuesto',
            activeUrl: '/portfolio/cotizador',
            animation: 'top',
          },
          {
            label: 'Plantillas de cotización',
            url: '/portfolio/plantillas-cotizacion',
            activeUrl: '/portfolio/plantillas-cotizacion',
            animation: 'top',
          },
          {
            label: 'Proyectos',
            url: '/portfolio/proyectos',
            activeUrl: '/portfolio/proyectos',
            animation: 'top',
          },
          {
            label: 'Borradores',
            url: '/portfolio/borradores',
            activeUrl: '/portfolio/borradores',
            animation: 'top',
          },
        ],
      },
      {
        label: 'Recursos',
        icon: '/assets/Icons/Sidebar/team.svg',
        url: '/recursos/empleados',
        activeUrl: '/recursos',
        animation: 'top',
        canBeOpened: true,
        nestedLinks: [
          {
            label: 'Empleados',
            url: '/recursos/empleados',
            activeUrl: '/recursos/empleados',
            animation: 'top',
          },
          {
            label: 'Futuribles',
            url: '/recursos/futuribles',
            activeUrl: '/recursos/futuribles',
            animation: 'top',
            disabled: true,
          },
        ],
      },
      {
        label: 'Implicación',
        icon: '/assets/Icons/Sidebar/implicacion.svg',
        url: '/implicacion/registro-de-fichajes',
        activeUrl: '/implicacion',
        animation: 'top',
        canBeOpened: true,
        nestedLinks: [
          {
            label: 'Sprint',
            url: '/implicacion/sprint',
            activeUrl: '/implicacion/sprint',
            animation: 'top',
            disabled: true,
          },
          {
            label: 'Daily',
            url: '/implicacion/daily',
            activeUrl: '/implicacion/daily',
            animation: 'top',
            disabled: true,
          },
          {
            label: 'Registro tareas',
            url: '/implicacion/registro',
            activeUrl: '/implicacion/registro',
            animation: 'top',
            disabled: true,
          },
          {
            label: 'Seguimiento diario',
            url: '/implicacion/seguimiento',
            activeUrl: '/implicacion/seguimiento',
            animation: 'top',
            disabled: true,
          },
          {
            label: 'Registro de fichajes',
            url: '/implicacion/registro-de-fichajes',
            activeUrl: '/implicacion/registro-de-fichajes',
            animation: 'top',
          },
        ],
      },
      {
        label: 'Contabilidad',
        icon: '/assets/Icons/Sidebar/tesoreria.svg',
        url: '/contabilidad',
        activeUrl: '/contabilidad',
        animation: 'top',
        canBeOpened: true,
        disabled: true,
        nestedLinks: [
          {
            label: 'Tesorería',
            url: '/contabilidad/tesoreria',
            activeUrl: '/contabilidad/tesoreria',
            animation: 'top',
          },
          {
            label: 'Ingresos',
            url: '/contabilidad/ingresos',
            activeUrl: '/contabilidad/ingresos',
            animation: 'top',
          },
          {
            label: 'Costes',
            url: '/contabilidad/costes',
            activeUrl: '/contabilidad/costes',
            animation: 'top',
          },
          {
            label: 'Inventario',
            url: '/contabilidad/inventario',
            activeUrl: '/contabilidad/inventario',
            animation: 'top',
          },
          {
            label: 'Bonos de horas',
            url: '/contabilidad/bonos',
            activeUrl: '/contabilidad/bonos',
            animation: 'top',
          },
          {
            label: 'Proveedores',
            url: '/contabilidad/proveedores',
            activeUrl: '/contabilidad/proveedores',
            animation: 'top',
          },
        ],
      },
      {
        label: 'CRM',
        icon: '/assets/Icons/Sidebar/crm.svg',
        url: '/crm',
        activeUrl: '/crm',
        animation: 'top',
        canBeOpened: true,
        disabled: true,
        nestedLinks: [
          {
            label: 'Funnel',
            url: '/crm/Funnel',
            activeUrl: '/crm/Funnel',
            animation: 'top',
          },
          {
            label: 'Clientes',
            url: '/crm/clientes',
            activeUrl: '/crm/clientes',
            animation: 'top',
          },
        ],
      },
      {
        label: 'Ajustes generales',
        icon: '/assets/Icons/Sidebar/settings.svg',
        url: '/ajustes-generales/mi-perfil',
        activeUrl: '/ajustes-generales',
        animation: 'top',
        canBeOpened: true,
        nestedLinks: [
          {
            label: 'Preferencias',
            url: '/ajustes-generales/preferencias',
            activeUrl: '/ajustes-generales/preferencias',
            animation: 'top',
          },
          {
            label: 'Roles y permisos',
            url: '/ajustes-generales/roles-permisos',
            activeUrl: '/ajustes-generales/roles-permisos',
            animation: 'top',
          },
          {
            label: 'Opciones de cuenta',
            url: '/ajustes-generales/opciones-de-cuenta',
            activeUrl: '/ajustes-generales/opciones-de-cuenta',
            animation: 'top',
          },
          {
            label: 'Mi perfil',
            url: '/ajustes-generales/mi-perfil',
            activeUrl: '/ajustes-generales/mi-perfil',
            animation: 'top',
          },
          {
            label: 'Soporte técnico',
            url: '/ajustes-generales/soporte-tecnico',
            activeUrl: '/ajustes-generales/soporte-tecnico',
            animation: 'top',
          },
        ],
      },
    ],
    alwaysUnfold: true,
    logo: {
      url: '/assets/Images/Logos/Fenech_V2-20.png',
      defaultRoute: '/inicio',
    },
  };
  private sidebarEmployeeNavigationMenuOptions: iNavigationMenuOptions = {
    type: 'sidebar',
    links: [
      {
        label: 'Implicación',
        icon: '/assets/Icons/Sidebar/implicacion.svg',
        url: '/implicacion/registro-de-fichajes',
        activeUrl: '/implicacion',
        animation: 'top',
        canBeOpened: true,
        nestedLinks: [
          {
            label: 'Registro de fichajes',
            url: '/implicacion/registro-de-fichajes',
            activeUrl: '/implicacion/registro-de-fichajes',
            animation: 'top',
          },
        ],
      },

      {
        label: 'Ajustes generales',
        icon: '/assets/Icons/Sidebar/settings.svg',
        url: '/ajustes-generales/mi-perfil',
        activeUrl: '/ajustes-generales',
        animation: 'top',
        canBeOpened: true,
        nestedLinks: [
          {
            label: 'Mi perfil',
            url: '/ajustes-generales/mi-perfil',
            activeUrl: '/ajustes-generales/mi-perfil',
            animation: 'top',
          },
          {
            label: 'Soporte técnico',
            url: '/ajustes-generales/soporte-tecnico',
            activeUrl: '/ajustes-generales/soporte-tecnico',
            animation: 'top',
          },
        ],
      },
    ],
    alwaysUnfold: true,
    logo: {
      url: '/assets/Images/Logos/Fenech_V2-20.png',
      defaultRoute: '/inicio',
    },
  };
  private roleAdmin: string = iStaticUtilities.simpleCrypt('ROLE_ADMIN');
  private roleIntermediate: string =
    iStaticUtilities.simpleCrypt('ROLE_INTERMEDIO');
  private roleEmployee: string = iStaticUtilities.simpleCrypt('ROLE_USER');

  constructor() {}

  static getSidebarNavigationMenuOptions() {
    return new StaticDataHandlerService().sidebarNavigationMenuOptions;
  }
  static getSidebarEmployeeNavigationMenuOptions() {
    return new StaticDataHandlerService().sidebarEmployeeNavigationMenuOptions;
  }
  static getEncryptedRoleAdmin() {
    return new StaticDataHandlerService().roleAdmin;
  }
  static getEncryptedRoleIntermediate() {
    return new StaticDataHandlerService().roleIntermediate;
  }
  static getEncryptedRoleEmployee() {
    return new StaticDataHandlerService().roleEmployee;
  }
}
