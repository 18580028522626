import { Component, OnInit } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { HubApiCallsHandlerClass } from './class/hub-api-calls-handler-class';
import { HubVariableHandlerClass } from './class/hub-variable-handler-class';
import { slideNavigationTypes } from '@quasar-dynamics/ngx-qd-design-system';

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss'],
})
export class HubComponent implements OnInit {
  constructor(
    protected DDISE: DefaultDependenciesInjectorService,
    public apiCallsHandlerClass: HubApiCallsHandlerClass,
    public variableCallsHandlerClass: HubVariableHandlerClass,
  ) {
    apiCallsHandlerClass = new HubApiCallsHandlerClass(
      variableCallsHandlerClass,
    );
  }
  ngOnInit(): void {
    this.variableCallsHandlerClass.userRoleHandler();
    this.variableCallsHandlerClass.getTimeOfDay();
    this.variableCallsHandlerClass.getNameOfUser();
  }

  /**
   * FUNCTIONALITIES
   */
  dispatchGoToLink(link: any) {
    this.DDISE.staticUtilities.goTo(link.url, slideNavigationTypes.slideToTop);
  }
}
