import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketUtilitiesService {
  socket$: WebSocketSubject<any> = new WebSocketSubject(
    environment.urlWebSocket,
  );
  subscription: Subscription | undefined;

  constructor() {}

  getWSMessages() {
    this.socket$.subscribe(
      (message) => {
        console.log('Mensaje recibido: ' + message.msg);
        // Aquí puedes manejar el mensaje recibido del servidor WebSocket
      },
      (error) => {
        console.error('Error:', error);
      },
      () => {
        console.warn('Conexión cerrada');
      },
    );
  }
  postWSMessages() {
    this.socket$.next('hola');
  }

  destroyWS() {
    this.subscription?.unsubscribe();
    this.socket$.complete();
  }
}
