import { RegisterComponent } from 'src/app/Pages/register/register.component';
import { HubComponent } from 'src/app/Pages/hub/hub.component';
import { RecoveryPasswordComponent } from 'src/app/Pages/access-management/recovery-password/recovery-password.component';

import { SendRecoveryEmailComponent } from 'src/app/Pages/access-management/send-recovery-email/send-recovery-email.component';
import { LoginComponent } from 'src/app/Pages/access-management/login/login.component';

import { PageNotFoundComponent } from 'src/app/Pages/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecoveryPasswordEmailNotificationComponent } from './Pages/access-management/recovery-password-email-notification/recovery-password-email-notification.component';
import { LoggedGuard } from './Shared/Guards/logged.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'registro',
    component: RegisterComponent,
  },
  {
    path: 'recuperacion-de-cuenta',
    component: SendRecoveryEmailComponent,
    data: { title: 'Enviar email de recuperación de contraseña' },
  },
  {
    path: 'crear-nueva-contraseña',
    component: RecoveryPasswordComponent,
    data: {
      title: 'Crear nueva contraseña',
    },
  },
  {
    path: 'notificacion-recuperacion-contraseña',
    component: RecoveryPasswordEmailNotificationComponent,
    data: {
      title: 'Notificación de envío de email de recuperación de contraseña',
    },
  },
  {
    path: 'inicio',
    component: HubComponent,
    canActivate: [LoggedGuard],
    data: {
      title: '¡Bienvenido a fennech!',
    },
  },
  {
    path: 'ajustes-generales',
    loadChildren: () =>
      import('./Pages/general-settings/general-settings.module').then(
        (m) => m.GeneralSettingsModule,
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
    data: { title: 'La página que busca no existe' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
