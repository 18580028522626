import { Component, Injector, OnInit } from '@angular/core';
import { slideNavigationTypes } from '@quasar-dynamics/ngx-qd-design-system';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  constructor(private DDISE: DefaultDependenciesInjectorService) {}

  ngOnInit(): void {}

  /**
   * FUNCTIONALITIES
   */
  dispatchGoToHub() {
    this.DDISE.staticUtilities.goTo(
      '/inicio',
      slideNavigationTypes.slideToBottom,
    );
  }
}
