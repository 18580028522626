import { Injectable } from '@angular/core';
import { RecoveryPasswordEmailNotificationVariableHandlerClass } from './recovery-password-email-notification-variable-handler-class';
import { iResultHttp } from '@quasar-dynamics/ngx-qd-design-system';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';

@Injectable({
  providedIn: 'root',
})
export class RecoveryPasswordEmailNotificationApiCallsHandlerClass {
  constructor(
    public variableCallsHandlerClass: RecoveryPasswordEmailNotificationVariableHandlerClass,
    public DDISE: DefaultDependenciesInjectorService,
  ) {}

  /**
   * API RESPONSES
   */
  successResendRecoveryPasswordResponseHandler(res: iResultHttp) {
    this.DDISE.generalLoader.removeFromLoaderAmount();
  }

  /**
   * CALLS TO API
   */
  resendRecoveryPassword() {}
}
