import { Injectable } from '@angular/core';
import { LoginVariableHandlerClass } from './login-variable-handler-class';
import { LoginService } from 'src/app/Services/Api/login.service';
import {
  StaticUtilitiesService,
  iResultHttp,
  slideNavigationTypes,
} from '@quasar-dynamics/ngx-qd-design-system';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginApiCallsHandlerClass {
  constructor(
    public variableCallsHandlerClass: LoginVariableHandlerClass,
    public DDISE: DefaultDependenciesInjectorService,
    private loginSE?: LoginService,
    private userSE?: UserService,
  ) {}

  /**
   * API RESPONSES
   */
  successLoginResponseHandler() {
    this.DDISE.generalLoader.removeFromLoaderAmount();
    StaticUtilitiesService.showFeedback('Se ha iniciado sesión correctamente');
    this.getMyData();
  }
  errorLoginResponseHandler(res: iResultHttp) {
    this.DDISE.generalLoader.removeFromLoaderAmount();
    res.status === 401
      ? StaticUtilitiesService.showError('Inicio de sesión no válido')
      : null;
  }
  successGetMyDataResponseHandler(res: iResultHttp) {
    const { data } = res;
    this.variableCallsHandlerClass.setDataOnLocalStorage(data);
    this.DDISE.generalLoader.removeFromLoaderAmount();
    this.DDISE.staticUtilities.goTo('/inicio', slideNavigationTypes.slideToTop);
  }

  /**
   * CALLS TO API
   */
  login() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.DDISE.generalLoader.addToLoaderAmount();
    this.loginSE?.login(
      behaviorSubject,
      this.variableCallsHandlerClass.getEncryptedLoginData(),
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: () => this.successLoginResponseHandler(),
          error: false,
        },
        {
          method: () => this.errorLoginResponseHandler(res),
          error: true,
        },
      ]);
    });
  }
  getMyData() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.DDISE.generalLoader.addToLoaderAmount();

    this.userSE?.getMyData(behaviorSubject);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: () => this.successGetMyDataResponseHandler(res),
          error: false,
        },
        {
          method: () => this.DDISE.generalLoader.removeFromLoaderAmount(),
          error: true,
        },
      ]);
    });
  }
}
