import { Injectable } from '@angular/core';
import {
  iNavigationMenuOptions,
  iStaticUtilities,
} from '@quasar-dynamics/ngx-qd-design-system';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Injectable({
  providedIn: 'root',
})
export class HubVariableHandlerClass {
  /**
   * CONTROL VARIABLES
   */
  isAdmin: boolean = false;
  isIntermediate: boolean = false;
  isEmployee: boolean = false;

  /**
   * NAVIGATION OPTIONS
   */
  sidebarNavigationMenuOptions: iNavigationMenuOptions =
    StaticDataHandlerService.getSidebarEmployeeNavigationMenuOptions();

  /**
   * OTHER VARIABLES
   */
  name: string = '';
  dayTime: string = '';
  today: Date = new Date();
  atSing: string = '@';
  constructor() {}

  /**
   * GETTERS & SETTERS
   */
  getTimeOfDay() {
    const currentHour = new Date().getHours();
    if (currentHour >= 6 && currentHour < 12) {
      this.dayTime = 'Buenos días';
    } else if (currentHour >= 12 && currentHour < 18) {
      this.dayTime = 'Buenas tardes';
    } else {
      this.dayTime = 'Buenas noches';
    }
  }
  getNameOfUser() {
    this.name = iStaticUtilities.simpleDecrypt(
      localStorage.getItem(iStaticUtilities.simpleCrypt('name'))!,
    );
  }
  getUserRoleSidebarNavigationMenu() {
    if (this.isEmployee)
      this.sidebarNavigationMenuOptions =
        StaticDataHandlerService.getSidebarEmployeeNavigationMenuOptions();
    if (this.isIntermediate || this.isAdmin)
      this.sidebarNavigationMenuOptions =
        StaticDataHandlerService.getSidebarNavigationMenuOptions();
  }
  /**
   * HANDLERS
   */
  userRoleHandler() {
    const role: string = localStorage.getItem(
      iStaticUtilities.simpleCrypt('roles'),
    )!;
    if (role === StaticDataHandlerService.getEncryptedRoleAdmin())
      this.isAdmin = true;
    if (role === StaticDataHandlerService.getEncryptedRoleIntermediate())
      this.isIntermediate = true;
    if (role === StaticDataHandlerService.getEncryptedRoleEmployee())
      this.isEmployee = true;
    this.getUserRoleSidebarNavigationMenu();
  }
}
