<div class="recoveryPasswordGeneralContainer">
  <div class="left">
    <form
      (submit)="
        variableCallsHandlerClass.isDataCompleted &&
        variableCallsHandlerClass.isValidFormat
          ? dispatchRecoveryPasswordAction()
          : null
      "
    >
      <div class="logo">
        <img src="/assets/Images/Logos/Fenech_V2-20.png" />
      </div>
      <div class="welcomeMessageContainer">
        <p class="title">Recuperación de contraseña</p>
        <p class="subtitle">Introduce la nueva contraseña</p>
      </div>
      <div class="formElementsContainer">
        <customInput
          [options]="variableCallsHandlerClass.passwordOptions"
          [(ngModel)]="variableCallsHandlerClass.newPassData.new_password"
          name="password"
          (input)="variableCallsHandlerClass.checkIfCompletedAndValidFormat()"
        >
        </customInput>
        <btn
          class="action"
          [ngClass]="{
            disabled:
              !variableCallsHandlerClass.isDataCompleted ||
              !variableCallsHandlerClass.isValidFormat,
          }"
        >
          <ng-container>
            <span>Recuperar contraseña</span>
          </ng-container>
        </btn>
        <a class="recoveryPassword" routerLink="/login" routerAnimationTop
          >Ya he recordado mi contraseña</a
        >
      </div>
    </form>
    <accessManagementFormFooter />
  </div>
  <div class="right"></div>
</div>
