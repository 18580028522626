import { Component, OnInit } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { LoginApiCallsHandlerClass } from './class/login-api-calls-handler-class';
import { LoginVariableHandlerClass } from './class/login-variable-handler-class';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    protected DDISE: DefaultDependenciesInjectorService,
    public apiCallsHandlerClass: LoginApiCallsHandlerClass,
    public variableCallsHandlerClass: LoginVariableHandlerClass,
  ) {
    apiCallsHandlerClass = new LoginApiCallsHandlerClass(
      variableCallsHandlerClass,
      DDISE,
    );
  }

  ngOnInit(): void {}

  dispatchLoginAction() {
    this.apiCallsHandlerClass.login();
  }
}
