import { Injectable } from '@angular/core';
import {
  StaticUtilitiesService,
  iOptionsCustomInput,
  sha256,
} from '@quasar-dynamics/ngx-qd-design-system';

@Injectable({
  providedIn: 'root',
})
export class RecoveryPasswordVariableHandlerClass {
  /**
   * FORM VARIABLES
   */
  newPassData: { new_password: string; recover_token: string } = {
    new_password: '',
    recover_token: '',
  };

  /**
   * OTHER VARIABLES
   */
  isDataCompleted: boolean = false;
  isValidFormat: boolean = true;

  /**
   * INPUT OPTIONS
   */
  passwordOptions: iOptionsCustomInput = {
    placeholder: 'Nueva contraseña',
    type: 'password',
  };

  constructor() {}

  /**
   * VALIDATIONS
   */
  checkIfCompletedAndValidFormat() {
    this.isDataCompleted = StaticUtilitiesService.isCompleteObject(
      this.newPassData,
    );
  }

  /**
   * GETTERS & SETTERS
   */
  getNewPassObj() {
    const encrypteNewPassObject: {
      new_password: string;
      recover_token: string;
    } = {
      new_password: sha256(this.newPassData.new_password)!,
      recover_token: this.newPassData.recover_token,
    };
    return encrypteNewPassObject;
  }
}
