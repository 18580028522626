import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OverrideStaticUtilitiesService {
  constructor() {}

  static logOut() {
    localStorage.clear();
    sessionStorage.clear();
  }
  static isLogged() {
    if (
      OverrideStaticUtilitiesService.getToken() &&
      OverrideStaticUtilitiesService.getRefreshToken()
    ) {
      return true;
    }
    return false;
  }

  /**
   * PRIVATE FUNCTIONS
   */

  private static getToken() {
    return localStorage.getItem('token');
  }
  private static getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }
}
