<div class="registerGeneralContainer">
  <div class="left">
    <h1>
      Estás <b>a un paso </b>de <br />
      llevar tu productividad <br /><b>más allá.</b>
    </h1>
    <h2>Da de alta tu empresa en fennech</h2>
  </div>
  <div class="right">
    <div class="formContainer">
      <img [src]="'/assets/Images/Logos/logoFennechAnimado.gif'" />
      <customInput [options]="variableCallsHandlerClass.nameOptions" />
      <customInput [options]="variableCallsHandlerClass.firstSurnameOptions" />
      <customInput [options]="variableCallsHandlerClass.emailOptions" />
      <customInput [options]="variableCallsHandlerClass.passwordOptions" />
      <customInput [options]="variableCallsHandlerClass.companyOptions" />
      <div class="privacyPolicies">
        <mat-checkbox
          [disableRipple]="true"
          [(ngModel)]="variableCallsHandlerClass.isPrivacyPoliciesChecked"
        ></mat-checkbox>
        <span for=""
          >He leído y acepto la <a>política de privacidad</a> de fennech.</span
        >
      </div>
      <btn class="action">
        <ng-container>
          <span>Darse de alta</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>
