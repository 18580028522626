/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { iBaseObject } from '@quasar-dynamics/ngx-qd-design-system';

export interface iSelfData {
  id: number | null;
  username: string;
  roles: Array<string>;
  name: string;
  surname: string;
}

export class iSelfData extends iBaseObject {
  static convertFromCollection(ob: any): Array<iSelfData> {
    const iSelfDataCollection: Array<iSelfData> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iSelfData {
    const iSelfDataObj = new iSelfData();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.username = ob.username;
    iSelfDataObj.roles = ob.roles;
    iSelfDataObj.name = ob.nombre;
    iSelfDataObj.surname = ob.apellidos;
    return iSelfDataObj;
  }

  static createEmptyObject(): iSelfData {
    const iSelfDataObj = new iSelfData();
    return iSelfDataObj;
  }
}
