import { Component, OnInit } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';

@Component({
  selector: 'checkinFloattingButton',
  templateUrl: './checkin-floatting-button.component.html',
  styleUrls: ['./checkin-floatting-button.component.scss'],
})
export class CheckinFloattingButtonComponent implements OnInit {
  isValidRoute: boolean = false;
  constructor(private DDISE: DefaultDependenciesInjectorService) {}

  ngOnInit(): void {}
}
