<div class="confirmPopupGeneralContainer">
  <div class="body">
    <img *ngIf="modalData.icon" [src]="modalData.icon" />
    <p class="title">{{ modalData.title }}</p>
    <p class="description" *ngIf="modalData.description">
      {{ modalData.description }}
    </p>
    <p class="description" *ngIf="modalData.extraDescription">
      {{ modalData.extraDescription }}
    </p>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>{{ modalData.buttonCancelText }}</span>
      </ng-container>
    </btn>
    <btn class="action" (click)="closePopup()">
      <ng-container>
        <mat-icon>check</mat-icon>
        <span>{{ modalData.buttonConfirmText }}</span>
      </ng-container>
    </btn>
  </div>
</div>
