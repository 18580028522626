import { Component, OnInit } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { RecoveryPasswordEmailNotificationApiCallsHandlerClass } from './class/recovery-password-email-notification-api-calls-handler-class';
import { RecoveryPasswordEmailNotificationVariableHandlerClass } from './class/recovery-password-email-notification-variable-handler-class';

@Component({
  selector: 'app-recovery-password-email-notification',
  templateUrl: './recovery-password-email-notification.component.html',
  styleUrls: ['./recovery-password-email-notification.component.scss'],
})
export class RecoveryPasswordEmailNotificationComponent implements OnInit {
  constructor(
    protected DDISE: DefaultDependenciesInjectorService,
    public apiCallsHandlerClass: RecoveryPasswordEmailNotificationApiCallsHandlerClass,
    public variableCallsHandlerClass: RecoveryPasswordEmailNotificationVariableHandlerClass,
  ) {
    apiCallsHandlerClass =
      new RecoveryPasswordEmailNotificationApiCallsHandlerClass(
        variableCallsHandlerClass,
        DDISE,
      );
  }

  ngOnInit(): void {
    this.variableCallsHandlerClass.checkIfHasEmailHandler();
  }

  dispatchResendRecoveryEmailAction() {
    this.apiCallsHandlerClass.resendRecoveryPassword();
  }
}
