import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import {
  StaticUtilitiesService,
  slideNavigationTypes,
} from '@quasar-dynamics/ngx-qd-design-system';

import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/OverrideStaticUtilities.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate {
  constructor(protected DDISE: DefaultDependenciesInjectorService) {}

  canActivate(): boolean {
    if (!OverrideStaticUtilitiesService.isLogged()) {
      // this.loginSE.logOut();
      this.DDISE.staticUtilities.goTo('/', slideNavigationTypes.slideToBottom);
      StaticUtilitiesService.showError(
        'Para acceder a este recurso es necesario inciar sesión.',
      );
      return false;
    }

    return true;
  }
}
