import { transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AnimationControllerService,
  GeneralLoaderService,
  SnackBarService,
  StaticUtilitiesService,
  TokenManagmentService,
  VariablesDesignSystemService,
  iUnsubscribeDestroy,
  slideNavigationFunctions,
  slideNavigationTypes,
} from '@quasar-dynamics/ngx-qd-design-system';
import { filter, map, takeUntil } from 'rxjs';
import { OverrideStaticUtilitiesService } from './Services/Utils/OverrideStaticUtilities.service';
import { environment } from '../environments/environment';
import { LoginService } from './Services/Api/login.service';
import { FennechLoaderComponent } from './Shared/Components/fennech-loader/fennech-loader.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PWAHandlerService } from './Services/Utils/PWAHandler.service';
import { WebPushNotificationHandlerService } from './Services/Utils/WebPushNotificationHandler.service';
import { DefaultDependenciesInjectorService } from './Services/Utils/DefaultDependenciesInjector.service';
import { WebSocketUtilitiesService } from './Services/Utils/WebSocketUtilities.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition(
        '* => slideToLeft',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft),
      ),
      transition(
        '* => slideToRight',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToRight,
        ),
      ),
      transition(
        '* => slideToTop',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop),
      ),
      transition(
        '* => slideToBottom',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom,
        ),
      ),
      transition(
        '* => slideToLeftDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft),
      ),
      transition(
        '* => slideToRightDuplicate',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToRight,
        ),
      ),
      transition(
        '* => slideToTopDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop),
      ),
      transition(
        '* => slideToBottomDuplicate',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom,
        ),
      ),
    ]),
  ],
})
export class AppComponent
  extends iUnsubscribeDestroy
  implements OnInit, OnDestroy
{
  title = 'fennech';
  isValidRoute: boolean = true;
  constructor(
    private router: Router,
    private titleSE: Title,
    public animService: AnimationControllerService,
    private loginSE: LoginService,
    private generalLoaderSE: GeneralLoaderService,
    private snackBar: MatSnackBar,
    private DDISE: DefaultDependenciesInjectorService,
    private WSUSE: WebSocketUtilitiesService,
  ) {
    super();
    SnackBarService.initialize(this.snackBar);
  }
  override ngOnDestroy(): void {
    this.WSUSE.destroyWS();
  }
  ngOnInit(): void {
    VariablesDesignSystemService.setIsRelease(environment.production);
    VariablesDesignSystemService.setUrlApi(environment.urlApi);
    WebPushNotificationHandlerService.requestPermission();
    this.generalLoaderSE.setLoaderComponent(FennechLoaderComponent);
    this.WSUSE.getWSMessages();
    this.refreshTokenHandler();
    this.routerHandler();
    this.isValidRouteHandler();
    setTimeout(() => {
      this.WSUSE.postWSMessages();
    }, 2000);
  }

  /**
   * HANDLERS
   */

  refreshTokenHandler() {
    TokenManagmentService.listenerTokenLost()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        OverrideStaticUtilitiesService.logOut();
        setTimeout(() => {
          location.reload();
        }, 1000);
      });

    TokenManagmentService.listenerStartRefreshToken()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!location.pathname.includes('login')) {
          this.loginSE.refreshToken();
        }
      });
  }

  routerHandler() {
    this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          this.isValidRouteHandler();
          return routeTitle;
        }),
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleSE.setTitle(this.title);
        }
      });
  }
  /**
   * HANDLERS
   */
  isValidRouteHandler() {
    switch (this.DDISE.staticUtilities.getActualRoute()) {
      case '/login':
      case '/recuperacion-de-cuenta':
      case '/crear-nueva-contraseña':
      case '/notificacion-recuperacion-contraseña':
      case '/registro':
        this.isValidRoute = false;
        break;

      default:
        this.isValidRoute = true;
        break;
    }
  }
}
