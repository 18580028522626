import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import localEs from '@angular/common/locales/es';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxQdDesignSystemModule } from '@quasar-dynamics/ngx-qd-design-system';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from './Shared/Adapters/CustomDateAdapter';
import { LoginComponent } from 'src/app/Pages/access-management/login/login.component';
import { SendRecoveryEmailComponent } from 'src/app/Pages/access-management/send-recovery-email/send-recovery-email.component';
import { RecoveryPasswordComponent } from 'src/app/Pages/access-management/recovery-password/recovery-password.component';
import { PageNotFoundComponent } from 'src/app/Pages/page-not-found/page-not-found.component';
import { FennechLoaderComponent } from './Shared/Components/fennech-loader/fennech-loader.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RecoveryPasswordEmailNotificationComponent } from 'src/app//Pages/access-management/recovery-password-email-notification/recovery-password-email-notification.component';
import { AccessManagementFormFooterComponent } from './Shared/Layout/access-management-form-footer/access-management-form-footer.component';
import { HubComponent } from 'src/app/Pages/hub/hub.component';
import { CustomHeaderComponent } from './Shared/Layout/custom-header/custom-header.component';
import { ConfirmPopupComponent } from './Popups/confirm-popup/confirm-popup.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PwaPromptComponent } from './Shared/Layout/pwa-prompt/pwa-prompt.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PWAHandlerService } from './Services/Utils/PWAHandler.service';
import { CheckinFloattingButtonComponent } from './Shared/Layout/checkin-floatting-button/checkin-floatting-button.component';
import { RegisterComponent } from 'src/app/Pages/register/register.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

const initializer = (pwaService: PWAHandlerService) => () =>
  pwaService.initPwaPrompt();
registerLocaleData(localEs, 'es');
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SendRecoveryEmailComponent,
    RecoveryPasswordComponent,
    PageNotFoundComponent,
    FennechLoaderComponent,
    RecoveryPasswordEmailNotificationComponent,
    AccessManagementFormFooterComponent,
    HubComponent,
    CustomHeaderComponent,
    ConfirmPopupComponent,
    PwaPromptComponent,
    CheckinFloattingButtonComponent,
    RegisterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxQdDesignSystemModule,
    BrowserAnimationsModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    MatIconModule,
    MatIconModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatToolbarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [PWAHandlerService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
