import { Component } from '@angular/core';

@Component({
  selector: 'accessManagementFormFooter',
  templateUrl: './access-management-form-footer.component.html',
  styleUrl: './access-management-form-footer.component.scss',
})
export class AccessManagementFormFooterComponent {
  currentYear: number = new Date().getFullYear();
}
