import { Injectable } from '@angular/core';
import { iOptionsCustomInput } from '@quasar-dynamics/ngx-qd-design-system';

@Injectable({
  providedIn: 'root',
})
export class RegisterVariableHandlerClass {
  /**
   * CONTROLL VARIABLES
   */
  isPrivacyPoliciesChecked: boolean = false;

  /**
   * CUSTOM INPUT OPTIONS
   */
  nameOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Nombre',
    required: true,
    classList: 'specialFormInput',
  };
  firstSurnameOptions: iOptionsCustomInput = {
    label: 'Apellido',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  emailOptions: iOptionsCustomInput = {
    label: 'Correo electrónico',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  passwordOptions: iOptionsCustomInput = {
    label: 'Contraseña',
    type: 'password',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  companyOptions: iOptionsCustomInput = {
    label: 'Empresa',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };

  constructor() {}

  /**
   * VALIDATIONS
   */
  checkIfIsCompleteDataHandler() {
    this.isPrivacyPoliciesChecked = !this.isPrivacyPoliciesChecked;
  }
}
