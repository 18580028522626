<div class="customHeaderGeneralContainer">
  <div class="pageInfo">
    <p class="title">{{ title }}</p>
  </div>
  <div class="mainActions">
    <span class="userActions" (click)="confirmLogOut()">
      <mat-icon>logout</mat-icon>
    </span>
  </div>
</div>
