import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  StaticUtilitiesService,
  iStaticUtilities,
  slideNavigationTypes,
} from '@quasar-dynamics/ngx-qd-design-system';

import { takeUntil } from 'rxjs';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { iConfirmPopup } from '../../Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from 'src/app/Popups/confirm-popup/confirm-popup.component';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/OverrideStaticUtilities.service';

@Component({
  selector: 'breadCrumHeader',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements OnInit {
  name: string = localStorage.getItem('nombre')!;
  actualRoute: string = '';
  title: string = '';
  isButtonActive: boolean = false;
  dayTime: string = '';
  constructor(
    private activeRoute: ActivatedRoute,
    private DDISE: DefaultDependenciesInjectorService,
  ) {}

  ngOnInit(): void {
    this.actualRoute = this.DDISE.staticUtilities.getActualRoute();
    this.title = this.activeRoute.snapshot.data['breadcrumbName'];
    this.isButtonActive = iStaticUtilities.normalizeBoolean(
      this.activeRoute.snapshot.data['button'],
    );
  }

  /**
   * POPUPS
   */

  confirmLogOut() {
    const confirmCloseResponseMessage: iConfirmPopup = {
      buttonConfirmText: 'Sí, confirmar',
      buttonCancelText: 'No, cancelar',
      title: '¿Quieres cerrar tu sesión?',
      icon: '/assets/Icons/log-out.svg',
    };
    const subject = StaticUtilitiesService.createSubject();
    this.DDISE.popup.openPopup(
      ConfirmPopupComponent,
      confirmCloseResponseMessage,
    );
    this.DDISE.popup
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) return;
        if (res.activeAction) {
          this.dispatchLogOutAction();
        }
        subject.next('');
      });
  }

  /**
   * FUNCTIONALITIES
   */
  dispatchLogOutAction() {
    OverrideStaticUtilitiesService.logOut();
    this.DDISE.staticUtilities.goTo(
      '/login',
      slideNavigationTypes.slideToBottom,
    );
    StaticUtilitiesService.showFeedback(
      'Se ha cerrado la sesión correctamente',
    );
  }
}
