<div class="registerReminderGeneralContainer">
  <div class="left">
    <h1>
      Estás <b>a un paso </b>de <br />
      recuperar tu cuenta <br />
    </h1>
  </div>
  <div class="right">
    <div class="formContainer">
      <img src="/assets/Images/Logos/logoFennechAnimado.gif" alt="" />
      <p class="title">Recupera tu cuenta de fennech</p>
      <p>
        El restablecimiento de tu contraseña es un paso crucial para garantizar
        la seguridad de tu cuenta y proteger tu información personal. Este
        proceso nos ayuda a confirmar que realmente eres tú quien está
        intentando acceder a la cuenta.
      </p>
      <p>
        Por favor, revisa tu bandeja de entrada y busca un correo electrónico de
        nuestra parte con el asunto "Recupera tu cuenta fennech". En el correo
        encontrarás un enlace que te llevará a completar el proceso de
        restauración. Una vez hecho esto, podrás acceder a tu cuenta y continuar
        utilizando nuestro servicio de manera segura.
      </p>
      <p>
        Recuerda que si no encuentras el correo de restauración en tu bandeja de
        entrada, te recomendamos revisar también la carpeta de correo no deseado
        o spam, ya que a veces nuestros mensajes pueden filtrarse allí por
        error.
      </p>
      <btn
        class="action"
        [ngClass]="{ disabled: !variableCallsHandlerClass.hasEmail }"
        (click)="dispatchResendRecoveryEmailAction()"
      >
        <ng-container>
          <span>Reenviar correo electrónico</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>
