import { Injectable } from '@angular/core';
import { iStaticUtilities } from '@quasar-dynamics/ngx-qd-design-system';

@Injectable({
  providedIn: 'root',
})
export class RecoveryPasswordEmailNotificationVariableHandlerClass {
  hasEmail: boolean = false;
  username: string = '';
  constructor() {}
  /**
   * VALIDATIONS
   */
  checkIfHasEmailHandler() {
    const auxUsername: string = localStorage.getItem(
      iStaticUtilities.simpleCrypt('email'),
    )!;
    if (auxUsername) {
      this.hasEmail = true;
      this.username = iStaticUtilities.simpleDecrypt(auxUsername);
    }
  }
}
