import { Injectable } from '@angular/core';
import { RecoveryPasswordVariableHandlerClass } from './recovery-password-variable-handler-class';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import {
  StaticUtilitiesService,
  iResultHttp,
  slideNavigationTypes,
} from '@quasar-dynamics/ngx-qd-design-system';
import { takeUntil } from 'rxjs';
import { RecoveryService } from 'src/app/Services/Api/recovery.service';

@Injectable({
  providedIn: 'root',
})
export class RecoveryPasswordApiCallsHandlerClass {
  constructor(
    public variableCallsHandlerClass: RecoveryPasswordVariableHandlerClass,
    public DDISE: DefaultDependenciesInjectorService,
    private recoverySE?: RecoveryService,
  ) {}

  /**
   * API RESPONSES
   */
  successRecoveryPasswordResponseHandler(res: iResultHttp) {
    this.DDISE.generalLoader.removeFromLoaderAmount();
    StaticUtilitiesService.showFeedback(String(res.message));
    this.DDISE.staticUtilities.goTo(
      '/login',
      slideNavigationTypes.slideToBottom,
    );
  }

  /**
   * CALLS TO API
   */
  recoveryPassword() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.DDISE.generalLoader.addToLoaderAmount();
    this.recoverySE?.recoveryPassword(
      behaviorSubject,
      this.variableCallsHandlerClass.getNewPassObj(),
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: () => this.successRecoveryPasswordResponseHandler(res),
          error: false,
        },
        {
          method: () => this.DDISE.generalLoader.removeFromLoaderAmount(),
          error: true,
        },
      ]);
    });
  }
}
