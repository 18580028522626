import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WebPushNotificationHandlerService {
  constructor() {}
  static requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('Permiso concedido para enviar notificaciones.');
          } else {
            console.log('Permiso denegado para enviar notificaciones.');
          }
        })
        .catch((error) => {
          console.error(
            'Error al solicitar permiso para notificaciones:',
            error,
          );
        });
    } else {
      console.log('Las notificaciones no están soportadas en este navegador.');
    }
  }

  static showNotification() {
    console.log('Notification permission:', Notification.permission);
    if (Notification.permission === 'granted') {
      console.log('Permission granted');
      navigator.serviceWorker
        .getRegistration()
        .then((registration) => {
          if (registration) {
            console.log('Service Worker registration found:', registration);
            registration.showNotification('Notificación de prueba', {
              body: 'Este es el cuerpo de la notificación.',
              icon: '/assets/Images/Logos/Fenech_V2-20.png',
            });
          } else {
            console.error('No hay un service worker registrado.');
          }
        })
        .catch((error) => {
          console.error(
            'Error al obtener el registro del Service Worker:',
            error,
          );
        });
    } else {
      console.error('Permiso de notificación no concedido.');
    }
  }
}
