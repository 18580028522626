<sidebarPageLayout
  [navigationMenuOptions]="
    variableCallsHandlerClass.sidebarNavigationMenuOptions
  "
>
  <ng-container>
    <breadCrumHeader />
    <h1>
      {{ variableCallsHandlerClass.dayTime }}
      <b>{{ variableCallsHandlerClass.name }}</b
      >, bienvenid{{ variableCallsHandlerClass.atSing }} de nuevo a fennech
    </h1>
    <h2>
      {{ variableCallsHandlerClass.today | date: "EEEE" | titlecase }},
      {{ variableCallsHandlerClass.today | date: "d 'd'e MMMM 'd'e y" }}
    </h2>

    <div class="cardGeneralContainer">
      @for (
        link of variableCallsHandlerClass.sidebarNavigationMenuOptions.links;
        track $index
      ) {
        <card
          [imageActive]="false"
          (click)="dispatchGoToLink(link)"
          [ngClass]="{ disabledCard: link.disabled }"
        >
          <ng-container bodyContent>
            <div class="headerContainer">
              <span class="title">
                <img [src]="link.icon" alt="" />
                {{ link.label }}
              </span>
            </div>

            <a [routerLink]="link.url" routerAnimationTop>
              Acceder
              <mat-icon>arrow_forward</mat-icon>
            </a>
          </ng-container>
        </card>
      }
    </div>
  </ng-container>
</sidebarPageLayout>
