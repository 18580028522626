<mat-toolbar
  *ngIf="data.mobileType === 'android'"
  color="primary"
  class="android-prompt"
>
  <div class="actionContainer">
    <button class="close" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
    <p>
      Para disfrutar de la experiencia completa de fennech en tu móvil, te
      recomendamos instalar la aplicación.
    </p>
    <btn class="action" (click)="installPwa()">
      <ng-container>
        <span>Añadir a la pantalla de inicio</span>
      </ng-container>
    </btn>
  </div>
</mat-toolbar>

<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
  <button class="close" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <p>
    Para disfrutar de la experiencia completa de fennech en tu iPhone, abre
    fennech en Safari, toca el botón de compartir y selecciona "Añadir a
    pantalla de inicio".
  </p>
  <div class="tutorial">
    <img src="/assets/Icons/Pwa/ios-menu-btn.png" />
    <mat-icon>navigate_next</mat-icon>
    <img src="/assets/Icons/Pwa/ios-add-btn.png" />
  </div>
</div>
